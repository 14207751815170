import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"

const Contacto = ({ data }) => {
  const { t } = useTranslation()

  const { language } = useI18next()
  const schema = {
    "@context": t("SEO.contacto.schema.context"),
    "@type": t("SEO.contacto.schema.type"),
    name: t("SEO.contacto.schema.name"),
    description: t("SEO.contacto.schema.description"),
    url: t("SEO.contacto.schema.url"),
    image: t("SEO.contacto.schema.image"),
    sameAs: t("SEO.contacto.schema.sameAs"),
    jobTitle: t("SEO.contacto.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.contacto.datos.title"),
    description: t("SEO.contacto.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <section className="container max-w-7xl mt-10 sm:mt-16 px-10 h-full">
        <h2 className="border-t border-b text-center py-4 text-3xl sm:text-4xl uppercase">
          {t("contacto")}
        </h2>
        <article className="flex flex-col items-center justify-center mt-10 lg:mb-96">
          <StaticImage
            src="../images/montse.jpg"
            alt="Montse Tixé"
            className="rounded-full w-48 h-48"
          />
          <h1 className="my-3 text-xl">{data.contacto.nombre}</h1>
          <a
            className="text-blanco text-lg"
            href="mailto:montsetixe@gmail.com?Subject=Solicitud%20de%20información"
          >
            {data.contacto.email}
          </a>
          <h2 className=" text-lg font-sans text-blanco">
            {data.contacto.telefono}
          </h2>
          <h2 className=" text-lg font-sans text-blanco">
            {data.contacto.localidad}
          </h2>
        </article>
      </section>
    </Layout>
  )
}

export default Contacto

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contacto: strapiContacto {
      nombre
      telefono
      localidad
      email
    }
  }
`
